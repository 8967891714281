// extracted by mini-css-extract-plugin
export var button = "ServicesPage-module--button--xLL7r";
export var content = "ServicesPage-module--content--vRvMQ";
export var heading = "ServicesPage-module--heading--vvcly";
export var image = "ServicesPage-module--image--DijsA";
export var imageContainer = "ServicesPage-module--image-container--1y6IK";
export var imageText = "ServicesPage-module--image-text--ZWYKi";
export var info = "ServicesPage-module--info--pIkE3";
export var infoList = "ServicesPage-module--info-list--vrGRu";
export var infoWrapper = "ServicesPage-module--info-wrapper--vtZlI";
export var service = "ServicesPage-module--service--5VSgQ";
export var wrapper = "ServicesPage-module--wrapper--G1qmA";