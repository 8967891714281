import React from 'react';
import Layout from '../components/Layout/Layout';
import ServicesPage from '../components/ServicesPage/ServicesPage';
const ServicesSite = () => {
  return(
    <Layout>
      <ServicesPage />
    </Layout>
  )
}

export default ServicesSite;
