import React from 'react';
import * as style from './ServicesPage.module.scss';
import {Link, graphql, StaticQuery} from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

const ServicesPage = () => {
	 return(
		 <div className={style.wrapper}>
		 	<div className={style.heading}>
				<h2>Services</h2>
				<p className={style.headline}>These are the services we offer at EST Football.</p>
			</div>
		 	<StaticQuery query={graphql`
				 {
					 allWpServices{
						 edges{
							 node{
								 title
								 services{
									 info
									 point1
									 point2
									 point3
									 point4
									 image{
										 gatsbyImage(width: 800, placeholder: BLURRED)
									 }
								 }
							 }
						 }
					 }
				 }
				 `} render={props => (
					 <div className={style.content}>
						 {props.allWpServices.edges.map(service => (
							 <div className={style.service}>
							 <div className={style.imageContainer}>
							 	<div className={style.imageText}>{service.node.title}</div>
							 	<GatsbyImage image={service.node.services.image.gatsbyImage} className={style.image} />

							 </div>

								 <div className={style.infoWrapper}>
								 <div dangerouslySetInnerHTML={{__html: service.node.services.info}} className={style.info}/>
									 <ul className={style.infoList}>
										 <li>{service.node.services.point1}</li>
										 <li>{service.node.services.point2}</li>
										 <li>{service.node.services.point3}</li>
										 <li>{service.node.services.point4}</li>
									 </ul>
									 <Link to='/contact' className={style.button}>Contact Us Now</Link>
								 </div>
							 </div>
						 ))}
					 </div>

				 )} />
		 </div>
	 )
}
export default ServicesPage;
